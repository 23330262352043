export const testimonial_data = [
	{
		title: 'Pixbet',
		desc: "A Place Igaming revolucionou a forma como gerenciamos nosso sistema de apostas online. A plataforma é intuitiva e robusta, oferecendo todas as ferramentas necessárias para garantir uma experiência segura e envolvente para nossos clientes. ",
		id: 'Pixbet',
		name: 'Lucas Garcia',
		img: '/images/testimonials/testimonial_1.jpg',
	},
	{
		title: 'Mega Apostas',
		desc: "Desde que implementamos o site da Place Igaming, nossas operações se tornaram mais eficientes e lucrativas. A equipe de suporte é extremamente competente e sempre pronta para ajudar. É pra quem busca uma plataforma confiável e inovadora.",
		id: 'Mega Apostas',
		name: 'Matheus Andrade',
		img: '/images/testimonials/testimonial_2.jpg',
	},
	{
		title: 'Playbet',
		desc: "A parceria com a Place Igaming foi um divisor de águas para nosso negócio de apostas. A plataforma é fácil de usar, segura e repleta de recursos avançados que encantam nossos usuários. Não poderíamos estar mais satisfeitos com os resultados.",
		id: 'Playbet',
		name: 'Ketlin Nunes',
		img: '/images/testimonials/testimonial_3.jpg',
	},
];
